import { CSSProperties } from 'react';
import { SelectedRowData } from '../contexts/TableConfigContext';

export interface RowRendererArgs {
	index: number;
	isScrolling: boolean;
	isVisible: boolean;
	key: string;
	parent: any;
	style: CSSProperties;
}

export interface Pagination {
	size: number;
	page: number;
	count: number;
	pageCount: number;
	totalCount: number;
	sort: string;
}

export enum SortDirection {
	ASCENDING = 'ascend',
	DESCENDING = 'descend',
}

export enum SortOrder {
	ASCENDING = 1,
	DESCENDING = -1,
}

export interface Sync {
	idx: number;
	more: boolean;
	size: number;
	sort: SortOrder;
	totalCount: number;
}

export const getSorter = (sort: string) => {
	let sortDirection: SortDirection = SortDirection.ASCENDING;
	let sortBy: string = sort;
	if (sort?.startsWith('-')) {
		sortDirection = SortDirection.DESCENDING;
		sortBy = sort.substr(1);
	}
	return { sortBy, sortDirection };
};

export const getSortString = (sortBy?: string, sortOrder?: SortDirection) =>
	sortBy !== undefined && sortOrder !== undefined
		? `${sortOrder === SortDirection.DESCENDING ? '-' : ''}${sortBy}`
		: undefined;

export enum DateRange {
	Today,
	Month,
	Year,
	Last7Days,
	Last30Days,
	CustomRange,
}

export enum ViewAsType {
	CARD = 'card',
	LIST = 'list',
}

export function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
	if (event.type.startsWith('touch')) return true;
	return false;
}

export interface MultiSelectContext {
	mode: boolean;
	selectedItems: SelectedRowData[];
	isAllSelected: boolean;
	selectedDateRange?: [number, number];
}

export const DEFAULT_MULTI_SELECT_CONTEXT: MultiSelectContext = {
	mode: false,
	selectedItems: [],
	isAllSelected: false,
};

export enum Folder {
	PUBLISHED = 'published',
	UNPUBLISHED = 'unpublished',
}

export type FolderType = Folder;
