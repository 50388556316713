// This contains all variables that will be the same across the app

import { LanguageOptions } from './localization';

interface GlobalVariables {
	authenticated: boolean;
	hasCheckedSSO: boolean;
	authLanguage?: LanguageOptions;
	isMobile: boolean;
}

let globalVariables: GlobalVariables = {
	authenticated: false, // Authentication should only be performed once across the whole web app
	hasCheckedSSO: false, // Used at HomePage to determine if we should redirect user for SSO checking
	authLanguage: undefined, // Language used to create redirection URL for token fetching
	isMobile: false, // A flag to allow other parts of the code to determine if in mobile mode
};

export function updateGlobalVariables(value: Partial<typeof globalVariables>) {
	globalVariables = {
		...globalVariables,
		...value,
	};
}

export function getGlobalVariables() {
	return globalVariables;
}
