import { ChecklistItemNecessity, ChecklistItemRequirementType, GenericChecklistItem } from './checklist';

export interface ChecklistTemplateResponseItem {
	id: string;
	type: any;
	name: string;
	necessity: ChecklistItemNecessity;
	requirements: {
		type: ChecklistItemRequirementType;
		required: boolean;
	}[];
	options: {
		value: string;
		text: string;
	}[];
}

export interface ChecklistTemplateResponseSection {
	name: string;
	id: string;
	items: ChecklistTemplateResponseItem[];
}
export interface ChecklistTemplate {
	id: string;
	name: string;
	created: number;
	createdBy: number;
	updated: number;
	updatedBy: number;
	published: boolean;
	publishedAt?: number;
	publishedBy?: number;
	signatures: number;
	sections: ChecklistTemplateResponseSection[];
}

export interface ReportChecklistTemplate extends Omit<ChecklistTemplate, 'sections'> {
	sections: { items: GenericChecklistItem[] }[];
}

export enum SortByField {
	NAME = 'name',
	CREATOR = 'creator',
	CREATED_DATE = 'created',
	UPDATED_DATE = 'updated',
}

export interface Aggregation {
	count: { published: number; unpublished: number };
}

export enum TemplateRouteActions {
	EDIT = 'edit',
	DUPLICATE = 'duplicate',
	PUBLISH = 'publish',
	DELETE = 'delete',
	IMPORT = 'import',
}

export interface NameContext {
	name: string;
	isValidated: boolean;
	error?: string;
}
