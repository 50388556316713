import { Field, MasterField, NumberValueType } from './spotForm';
import { FormatIds } from './reactIntl';
import { SpotTypes } from './spot';

export enum SortByField {
	NAME = 'name',
	CREATOR = 'creator',
	CREATED_DATE = 'created',
	UPDATED_DATE = 'updated',
}

export enum SpotTemplateType {
	CUSTOM = 0,
	ASSET,
	NEN,
}

export interface SpotTemplate {
	id: number;
	name: string;
	type: SpotTemplateType;
	created: number;
	createdBy: number;
	updated: number;
	updatedBy: number;
	published: boolean;
	publishedAt?: number;
	publishedBy?: number;
	fields: Field[];
	shortcut: SpotTypes;
}

export interface SpotAggregation {
	count: { published: number; unpublished: number; draft: number };
}

export interface NumberValueTypesDropdown {
	labelFormatID: FormatIds;
	value: NumberValueType;
	defaultValueUnit?: string;
}

export interface SpotTemplateJson {
	name: string;
	topicFields: MasterField[];
	topicTemplateFields: Field[];
}

export enum SpotTemplateRouteActions {
	NEW = 'new',
	EDIT = 'edit',
	DUPLICATE = 'duplicate',
	PUBLISH = 'publish',
	DELETE = 'delete',
	IMPORT = 'import',
	IMPORT_FILE = 'import_file',
}

export const NewSpotTemplateRouteActions = [
	SpotTemplateRouteActions.NEW,
	SpotTemplateRouteActions.IMPORT,
	SpotTemplateRouteActions.IMPORT_FILE,
];

export type NewSpotTemplateRouteActionType = (typeof NewSpotTemplateRouteActions)[number];

export interface SpotTemplateRouteState {
	templateName?: string; // for new template only
	sourceCompanyID?: string;
	sourceProjectID?: string;
	sourceTemplateID?: number;
	templateType?: SpotTemplateType;
}
