export function enumToArray<T>(enumValue: { [s: string]: T }): Exclude<T, string>[] {
	return Object.values(enumValue).filter((val) => typeof val !== 'string') as Exclude<T, string>[];
}

export function stringEnumToArray<T>(enumValue: { [s: string]: T }): T[] {
	return Object.values(enumValue);
}

export function enumToKeyMapping<T extends any>(enumValue: {
	[s: string]: T;
	// @ts-ignore
}): { [key in Exclude<T, string>]: string } {
	const enumArray = enumToArray(enumValue);
	const mapping: any = {};
	for (const value of enumArray) {
		mapping[value as any] = (enumValue as any)[value];
	}
	return mapping;
}
