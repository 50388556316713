// eslint-disable-next-line func-names
Element.prototype.myParentElement = function () {
	return this.parentElement || document.body;
};

// eslint-disable-next-line func-names
DOMRect.prototype.intersects = function (rect: DOMRect) {
	return this.left <= rect.right && rect.left <= this.right && this.top <= rect.bottom && rect.top <= this.bottom;
};

export {};
